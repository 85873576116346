import api, { PermissionType, UnderwritingCategory } from '@api';
import {
    ArrowDownward, ArrowUpward, Edit, RemoveCircleOutline
} from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import {
    IconButton, SubtleLink, useConfirm, usePageMessage
} from '@tsp-ui/core';
import { useGetCurrentAccount, useHasPermission } from '@utils';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { UnderwritingCategoryContext } from './UnderwritingCategoriesPage';
import styles from './UnderwritingCategoryConfigCard.module.scss';


interface UnderwritingCategoryConfigCardProps {
    underwritingCategoryConfig: UnderwritingCategory;
}

export function UnderwritingCategoryConfigCard({ underwritingCategoryConfig }: UnderwritingCategoryConfigCardProps) {
    const { underwritingCategoryConfigs, setUnderwritingCategoryConfigs } = useContext(UnderwritingCategoryContext);
    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ]);
    const pageMessage = usePageMessage();
    const confirm = useConfirm();
    const { id: clientId } = useGetCurrentAccount();

    const [ moveUpLoading, setMoveUpLoading ] = useState(false);
    const [ moveDownLoading, setMoveDownLoading ] = useState(false);
    const [ isDeleting, setIsDeleting ] = useState(false);

    async function moveConfig(direction: 'up' | 'down') {
        const setLoading = direction === 'up' ? setMoveUpLoading : setMoveDownLoading;
        setLoading(true);

        try {
            await api.underwriting.updateUnderwritingCategoryConfig(clientId, {
                ...underwritingCategoryConfig,
                displayOrder: underwritingCategoryConfig.displayOrder + (direction === 'up' ? -1 : 1)
            });

            setUnderwritingCategoryConfigs(await api.underwriting.getUnderwritingCategories(clientId));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while moving the underwriting category configuration', error);
        }

        setLoading(false);
    }

    async function onDelete() {
        if (!await confirm('Are you sure you want to delete this underwriting category configuration? All associated steps and questions will be deleted as well.')) {
            return;
        }

        setIsDeleting(true);

        try {
            await api.underwriting.deleteUnderwritingCategoryConfig(clientId, underwritingCategoryConfig.id);
            setUnderwritingCategoryConfigs(await api.underwriting.getUnderwritingCategories(clientId));

            pageMessage.success('Underwriting category configuration deleted');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while deleting the underwriting category configuration', error);
        }

        setIsDeleting(false);
    }

    return (
        <Paper
            className={styles.root}
            elevation={0}
        >
            <div>
                <SubtleLink
                    to={`detail/${underwritingCategoryConfig.id}`}
                >
                    {underwritingCategoryConfig.name}
                </SubtleLink>

                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {underwritingCategoryConfig.steps.length > 0
                        ? `${underwritingCategoryConfig.steps.length} steps`
                        : 'No steps have been added to this configuration'}
                </Typography>
            </div>

            <span className={styles.icons}>
                <IconButton
                    loading={moveUpLoading}
                    tooltip={!canManage ? 'You don\'t have permission to move configs' : 'Move up'}
                    disabled={underwritingCategoryConfig.displayOrder === 1
                        || !underwritingCategoryConfig.displayOrder || !canManage}
                    onClick={() => moveConfig('up')}
                >
                    <ArrowUpward color="secondary" />
                </IconButton>

                <IconButton
                    loading={moveDownLoading}
                    tooltip={canManage
                        ? 'You don\'t have permission to move configs' : 'Move down'}
                    disabled={
                        underwritingCategoryConfig.displayOrder >= underwritingCategoryConfigs.length
                            || underwritingCategoryConfig.displayOrder === null
                            || !canManage
                    }
                    onClick={() => moveConfig('down')}
                >
                    <ArrowDownward color="secondary" />
                </IconButton>

                <IconButton
                    tooltip={canManage ? 'Edit underwriting category configuration' : 'You do not have permission to edit underwriting category configurations'}
                    disabled={!canManage}
                    component={Link}
                    to={`${underwritingCategoryConfig.id}`}
                >
                    <Edit color="secondary" />
                </IconButton>

                <IconButton
                    tooltip={canManage ? 'Delete underwriting category configuration' : 'You do not have permission to delete underwriting category configurations'}
                    disabled={!canManage}
                    onClick={onDelete}
                    loading={isDeleting}
                    autoMarginLoader={false}
                >
                    <RemoveCircleOutline color="error" />
                </IconButton>
            </span>
        </Paper>
    );
}
