import api, {
    PermissionType, UnderwritingCategory,
    UnderwritingQuestionConfig,
    UnderwritingStepConfig
} from '@api';
import {
    Button, FilledSection, RoutedDialogManager, useAsyncEffect, usePageMessage
} from '@tsp-ui/core';
import {
    useGetCurrentAccount, useHandlePromiseSettledResult, useHasPermission, withAuth
} from '@utils';
import Page from '@views/components/Page';
import {
    Dispatch, SetStateAction, createContext, useCallback, useMemo, useState
} from 'react';
import { Link, useParams } from 'react-router-dom';

import AddEditQuestionDialog from './AddEditQuestionDialog';
import styles from './UnderwritingCategoryDetailPage.module.scss';
import { UnderwritingQuestionConfigCard } from './UnderwritingQuestionConfigCard';


export interface UnderwritingQuestionContextValue {
    questions: UnderwritingQuestionConfig[];
    setQuestions: Dispatch<SetStateAction<UnderwritingQuestionConfig[]>>;
}

export const UnderwritingQuestionContext = createContext<UnderwritingQuestionContextValue>({
    questions: [],
    setQuestions: () => {}
});

export default function UnderwritingStepDetailPage() {
    const { underwritingCategoryConfigId, underwritingStepConfigId } = useParams();
    const handlePromiseSettledResult = useHandlePromiseSettledResult();
    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ]);
    const pageMessage = usePageMessage();
    const { id: clientId } = useGetCurrentAccount();

    const [ loading, setLoading ] = useState(false);
    const [ configs, setConfigs ] = useState<UnderwritingCategory[]>([]);
    const [ steps, setSteps ] = useState<UnderwritingStepConfig[]>([]);
    const [ questions, setQuestions ] = useState<UnderwritingQuestionConfig[]>([]);

    useAsyncEffect(useCallback(async () => {
        if (!underwritingCategoryConfigId || !underwritingStepConfigId) {
            return;
        }

        setLoading(true);
        const [
            categoryConfigsResult, stepConfigsResult, questionConfigsResult
        ] = await Promise.allSettled([
            api.underwriting.getUnderwritingCategories(clientId),
            api.underwriting.getUnderwritingStepConfigs(clientId, underwritingCategoryConfigId),
            api.underwriting.getUnderwritingQuestionConfigs(
                clientId, underwritingCategoryConfigId, underwritingStepConfigId
            )
        ]);

        handlePromiseSettledResult(categoryConfigsResult, setConfigs, 'An error occurred while fetching underwriting category configs');
        handlePromiseSettledResult(stepConfigsResult, setSteps, 'An error occurred while fetching underwriting step configs');
        handlePromiseSettledResult(questionConfigsResult, setQuestions, 'An error occurred while fetching underwriting question configs');

        setLoading(false);
    }, [
        clientId, handlePromiseSettledResult, underwritingCategoryConfigId, underwritingStepConfigId
    ]));

    // Only reload questions when needed
    const reloadQuestions = useCallback(async () => {
        if (!underwritingCategoryConfigId || !underwritingStepConfigId) {
            return;
        }

        try {
            const questionConfigs = await api.underwriting.getUnderwritingQuestionConfigs(
                clientId,
                underwritingCategoryConfigId,
                underwritingStepConfigId
            );
            setQuestions(questionConfigs.sort((a, b) => a.displayOrder - b.displayOrder));
        } catch (e) {
            pageMessage.handleApiError('Error updating questions', e);
        }
    }, [
        underwritingCategoryConfigId, underwritingStepConfigId, clientId, pageMessage
    ]);

    const underwritingQuestionContextValue = useMemo(() => ({
        questions,
        setQuestions
    }), [ questions ]);

    const currentConfig = configs.find(c => c.id === underwritingCategoryConfigId);
    const currentStepConfig = steps.find(s => s.id === underwritingStepConfigId);
    const orderedQuestions = questions.sort((a, b) => a.displayOrder - b.displayOrder);

    return (
        <UnderwritingQuestionContext.Provider value={underwritingQuestionContextValue}>
            <Page
                header="Underwriting Step Details"
                loading={loading}
                breadcrumbs={[
                    'Underwriting Steps',
                    currentStepConfig?.name || ''
                ]}
            >
                <div className={styles.root}>
                    <FilledSection
                        className={styles.section}
                        header={(
                            <>
                                <span>
                                    Underwriting questions
                                </span>

                                <Button
                                    component={Link}
                                    to="add-question"
                                    disabled={!canManage}
                                    className={styles.headerButton}
                                >
                                    Add question
                                </Button>
                            </>
                        )}
                        noResultsMessage="No underwriting question configurations are currently configured."
                    >
                        {orderedQuestions?.map((question) => (
                            <UnderwritingQuestionConfigCard
                                key={question.id}
                                config={currentConfig!}
                                underwritingStepConfig={currentStepConfig!}
                                underwritingQuestionConfig={question}
                                onUpdate={reloadQuestions}
                            />
                        ))}
                    </FilledSection>
                </div>

                <RoutedDialogManager
                    routes={dialogRoutes}
                />
            </Page>
        </UnderwritingQuestionContext.Provider>
    );
}

const dialogRoutes = {
    'add-question': withAuth(AddEditQuestionDialog, [ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ], true),
    ':underwritingQuestionConfigId': withAuth(AddEditQuestionDialog, [ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ], true)
};
