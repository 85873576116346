import api, { UnderwritingCategory, UnderwritingData } from '@api';
import { List } from '@mui/material';
import {
    ListItemLink, Loader, getItemById, useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core';
import { useGetCurrentAccount, useHandlePromiseSettledResult } from '@utils';
import clsx from 'clsx';
import {
    Dispatch, SetStateAction, createContext, useCallback, useEffect, useState
} from 'react';

import ConditionsSummary from '../conditions/ConditionsSummary';

import styles from './UnderwritingCategories.module.scss';
import { UnderwritingCategoryDetail } from './UnderwritingCategoryDetail';


export interface UnderwritingCategoriesContextValue {
    underwritingCategories: UnderwritingCategory[];
    setUnderwritingCategories: Dispatch<SetStateAction<UnderwritingCategory[]>>;
    underwritingData: UnderwritingData | undefined;
    setUnderwritingData: Dispatch<SetStateAction<UnderwritingData | undefined>>;
}

export const UnderwritingCategoriesContext = createContext<UnderwritingCategoriesContextValue>({
    underwritingCategories: [],
    setUnderwritingCategories: () => {},
    underwritingData: undefined,
    setUnderwritingData: () => {}
});

export function UnderwritingCategories() {
    const { underwritingCategoryId, loanID } = useParams<{ loanID: string }>();
    const { id: clientId } = useGetCurrentAccount();
    const [ underwritingCategories, setUnderwritingCategories ] = useState<UnderwritingCategory[]>([]);
    const pageMessage = usePageMessage();
    const [ loading, setLoading ] = useState(true);
    const [ underwritingData, setUnderwritingData ] = useState<UnderwritingData | undefined>(undefined);
    const handlePromiseSettledResult = useHandlePromiseSettledResult();

    useAsyncEffect(useCallback(async () => {
        try {
            setUnderwritingCategories(await api.underwriting.getUnderwritingCategories(clientId));
            setUnderwritingData(await api.underwriting.getUnderwritingData(clientId, loanID));

            const [ categoriesResult, dataResult ] = await Promise.allSettled([
                api.underwriting.getUnderwritingCategories(clientId),
                api.underwriting.getUnderwritingData(clientId, loanID)
            ]);

            handlePromiseSettledResult(categoriesResult, setUnderwritingCategories,
                'An error occurred while fetching underwriting categories');

            handlePromiseSettledResult(dataResult, setUnderwritingData,
                'An error occurred while fetching underwriting data');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching underwriting categories', error);
        }

        setLoading(false);
    }, [
        clientId, handlePromiseSettledResult, loanID, pageMessage
    ]));

    useEffect(() => () => {
        if (underwritingCategories.length) {
            localStorage.setItem(loanID, JSON.stringify(underwritingCategories));
        }
    }, [ underwritingCategories, loanID ]);

    return (
        <UnderwritingCategoriesContext.Provider value={{
            underwritingCategories,
            setUnderwritingCategories,
            underwritingData,
            setUnderwritingData
        }}
        >
            <div className={styles.content}>
                {loading ? <Loader loading /> : (
                    <>
                        <List disablePadding>
                            <ListItemLink
                                to={`${underwritingCategoryId ? './../' : ''}all`}
                                selected={underwritingCategoryId === 'all'}
                                classes={{
                                    selected: clsx(styles.selected, {
                                        [styles.extraWidth]: false
                                    })
                                }}
                            >
                                All Conditions
                            </ListItemLink>

                            {underwritingCategories?.map((category) => (
                                <ListItemLink
                                    key={category.id}
                                    to={`${underwritingCategoryId ? './../' : ''}${category.id || ''}`}
                                    selected={
                                        !!underwritingCategoryId && underwritingCategoryId === category.id
                                    }
                                    classes={{
                                        selected: clsx(styles.selected, {
                                            [styles.extraWidth]: true
                                        })
                                    }}
                                >
                                    {category.name}
                                </ListItemLink>
                            ))}
                        </List>

                        {underwritingCategoryId && underwritingCategoryId !== 'all' ? (
                            <UnderwritingCategoryDetail
                                category={getItemById(underwritingCategories, underwritingCategoryId)}
                                underwritingCategoryId={underwritingCategoryId}
                            />
                        ) : (
                            <ConditionsSummary
                                header="All conditions"
                                className={styles.fullWidth}
                            />
                        )}
                    </>
                )}
            </div>
        </UnderwritingCategoriesContext.Provider>
    );
}
