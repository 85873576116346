import api, {  UnderwritingStepConfig } from '@api';
import { DialogActions, DialogContent } from '@mui/material';
import {
    Button, RoutedDialog, RoutedDialogImplProps, TextField, usePageMessage
} from '@tsp-ui/core';
import { useGetCurrentAccount } from '@utils';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { UnderwritingStepContext } from './UnderwritingCategoryDetailPage';


interface UnderwritingStepFormValues {
    name: string;
}

export default function AddEditStepDialog(props: RoutedDialogImplProps) {
    const { id: clientId } = useGetCurrentAccount();
    const { underwritingCategoryConfigId, underwritingStepConfigId } = useParams();
    const { setSteps, steps } = useContext(UnderwritingStepContext);
    const configToEdit = steps.find(({ id }) => id === underwritingStepConfigId!);
    const pageMessage = usePageMessage();
    const navigate = useNavigate();
    const formMethods = useForm<UnderwritingStepFormValues>({
        defaultValues: configToEdit
    });
    const { handleSubmit } = formMethods;
    const [ saveLoading, setSaveLoading ] = useState(false);

    async function onSubmit(data: UnderwritingStepFormValues) {
        setSaveLoading(true);
        try {
            if (configToEdit) {
                await api.underwriting.updateUnderwritingStepConfig(clientId, underwritingCategoryConfigId!, {
                    ...configToEdit,
                    name: data.name
                });
                pageMessage.success('Underwriting step configuration updated');
            } else {
                await api.underwriting.createUnderwritingStepConfig(
                    clientId,
                    underwritingCategoryConfigId!,
                    data.name
                );
                pageMessage.success('New underwriting step configuration created');
            }

            setSteps(await api.underwriting.getUnderwritingStepConfigs(
                clientId, underwritingCategoryConfigId!
            ));
            navigate(props.closeTo);
        } catch (error) {
            pageMessage.handleApiError('Error adding step', error);
        }
        setSaveLoading(false);
    }

    return (
        <RoutedDialog
            {...props}
            title={configToEdit ? 'Edit step' : 'Add step'}
            saveLoading={saveLoading}
        >
            <form
                noValidate
                id={formId}
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormProvider {...formMethods}>
                    <DialogContent>
                        <TextField<UnderwritingStepConfig>
                            name="name"
                            label="Step Name"
                            required
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            form={formId}
                            type="submit"
                        >
                            Save
                        </Button>
                    </DialogActions>
                </FormProvider>
            </form>
        </RoutedDialog>
    );
}

const formId = 'add-edit-underwriting-step-form';
