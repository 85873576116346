import { Paper } from '@mui/material';
import { Markdown } from '@views/components/Markdown';
import { useContext } from 'react';

import { UnderwritingCategoriesContext } from '../UnderwritingCategories';
import { UnderWritingStepContext } from '../UnderwritingStepSection';
import { UnderwritingQuestionCardProps, questionTypeToComponentMap } from '../underwriting-common';

import styles from './GroupQuestionCard.module.scss';

/**
 * A group question is a group of question that all appear in one <Paper> component.
 */
export function GroupQuestionCard({ question }: UnderwritingQuestionCardProps) {
    const { questionIdsToDisplay } = useContext(UnderWritingStepContext);
    const { underwritingData } = useContext(UnderwritingCategoriesContext);

    return (
        <Paper
            variant="outlined"
            className={styles.groupQuestionCard}
        >
            <Markdown valuesToInject={underwritingData}>
                {question.text}
            </Markdown>

            {question.questions?.map(question => {
                const UnderwritingQuestionCard = questionTypeToComponentMap[question.type];
                return questionIdsToDisplay.includes(question.id)
                    ? (
                        <UnderwritingQuestionCard
                            question={question}
                            key={question.id}
                        />
                    )
                    : null;
            })}
        </Paper>
    );
}
