import { apiUtils } from '../api-utils';


export function getUnderwritingCategories(clientId: string): Promise<UnderwritingCategory[]> {
    return apiUtils.get(`/client/${clientId}/underwriting-category`);
}

export function createUnderwritingCategoryConfig(clientId: string, underwritingCategoryConfigToCreate: Omit<UnderwritingCategory, 'id'>): Promise<UnderwritingCategory> {
    return apiUtils.post(`/client/${clientId}/underwriting-category`, underwritingCategoryConfigToCreate);
}

export function updateUnderwritingCategoryConfig(
    clientId: string, underwritingCategoryConfigToUpdate: UnderwritingCategory
): Promise<UnderwritingCategory> {
    return apiUtils.put(`/client/${clientId}/underwriting-category/${underwritingCategoryConfigToUpdate.id}`, underwritingCategoryConfigToUpdate);
}

export function deleteUnderwritingCategoryConfig(
    clientId: string, underwritingCategoryConfigId: string
): Promise<void> {
    return apiUtils.delete(`/client/${clientId}/underwriting-category/${underwritingCategoryConfigId}`);
}

export async function getUnderwritingStepDetail(
    clientId: string, underwritingCategoryId: string, underwritingStepId: string
): Promise<UnderwritingStepDetail> {
    return await apiUtils.get(`/client/${clientId}/underwriting-category/${underwritingCategoryId}/underwriting-step/${underwritingStepId}`);
}

export function updateUnderwritingStep(
    clientId: string, underwritingCategoryId: string, underwritingStep: UnderwritingStepDetail
): Promise<UnderwritingStepDetail> {
    return apiUtils.put(`/client/${clientId}/underwriting-category/${underwritingCategoryId}/underwriting-step/${underwritingStep.id}`, underwritingStep);
}

export function getUnderwritingData(clientId: string, loanId: string): Promise<UnderwritingData> {
    return apiUtils.get(`/client/${clientId}/loan/${loanId}/underwriting-data`);
}

export function getUnderwritingStepConfigs(
    clientId: string,
    underwritingCategoryConfigId: string
): Promise<UnderwritingStepConfig[]> {
    return apiUtils.get(`/client/${clientId}/underwriting-category/${underwritingCategoryConfigId}/step-config`);
}

export function createUnderwritingStepConfig(
    clientId: string,
    underwritingCategoryConfigId: string,
    stepName: string
): Promise<UnderwritingStepConfig> {
    return apiUtils.post(`/client/${clientId}/underwriting-category/${underwritingCategoryConfigId}/step-config`, { name: stepName });
}

export function updateUnderwritingStepConfig(
    clientId: string,
    underwritingCategoryConfigId: string,
    underwritingStepConfig: Partial<UnderwritingStepConfig>
): Promise<UnderwritingStepConfig> {
    return apiUtils.patch(`/client/${clientId}/underwriting-category/${underwritingCategoryConfigId}/step-config/${underwritingStepConfig.id}`, underwritingStepConfig);
}

export function deleteUnderwritingStepConfig(
    clientId: string, underwritingCategoryConfigId: string,
    underwritingStepConfigId: string
): Promise<void> {
    return apiUtils.delete(`/client/${clientId}/underwriting-category/${underwritingCategoryConfigId}/step-config/${underwritingStepConfigId}`);
}

export function getUnderwritingQuestionConfigs(
    clientId: string,
    underwritingCategoryConfigId: string,
    underwritingStepConfigId: string
): Promise<UnderwritingQuestionConfig[]> {
    return apiUtils.get(`/client/${clientId}/underwriting-category/${underwritingCategoryConfigId}/step-config/${underwritingStepConfigId}/question-config`);
}

export function createUnderwritingQuestionConfig(
    clientId: string,
    underwritingCategoryConfigId: string,
    underwritingStepConfigId: string,
    questionDescription: string
): Promise<UnderwritingQuestionConfig> {
    return apiUtils.post(`/client/${clientId}/underwriting-category/${underwritingCategoryConfigId}/step-config/${underwritingStepConfigId}/question-config`, { description: questionDescription });
}

export function updateUnderwritingQuestionConfig(
    clientId: string,
    underwritingCategoryConfigId: string,
    underwritingStepConfigId: string,
    underwritingQuestionConfig: Partial<UnderwritingQuestionConfig>
): Promise<UnderwritingQuestionConfig> {
    return apiUtils.patch(`/client/${clientId}/underwriting-category/${underwritingCategoryConfigId}/step-config/${underwritingStepConfigId}/question-config/${underwritingQuestionConfig.id}`, underwritingQuestionConfig);
}

export function deleteUnderwritingQuestionConfig(
    clientId: string, underwritingCategoryConfigId: string,
    underwritingStepConfigId: string, underwritingQuestionConfigId: string
): Promise<void> {
    return apiUtils.delete(`/client/${clientId}/underwriting-category/${underwritingCategoryConfigId}/step-config/${underwritingStepConfigId}/question-config/${underwritingQuestionConfigId}`);
}

export interface UnderwritingData {
    propertyAddress: string;
    commitmentExpiryDate: string;
    appraiserName: string;
    appraiserLicenseNumber: string;
    appraisalCompany: string;
    appraisalCompanyLicenseNumber: string;
    loanOfficerName: string;
    loanOfficerNmlsId: string;
    originatingCompanyName: string;
    originatingCompanyNmlsId: string;
    buyersAgentName: string;
    buyersAgentLicenseNumber: string;
    sellersAgentName: string;
    sellersAgentLicenseNumber: string;
    sellers: SellerBuyerInfo[];
    buyers: SellerBuyerInfo[];
}

export interface SellerBuyerInfo {
    name: string;
}

export interface UnderwritingCategory {
    id: string;
    name: string;
    displayOrder: number;
    steps: UnderwritingStep[];
    stepConfigs: UnderwritingStepConfig[];
}

export interface UnderwritingStep {
    id: string;
    name: string;
    isSubmitted?: boolean;
}

export interface UnderwritingStepDetail extends UnderwritingStep{
    questions: UnderwritingQuestion[];
}

export enum UnderwritingQuestionType {
    SINGLE_SELECT = 'SINGLE_SELECT',
    FORM = 'FORM',
    FOREACH = 'FOREACH',
    INFO = 'INFO',
    TABLE_ENTRY = 'TABLE_ENTRY',
    GROUP = 'GROUP',
    CUSTOM = 'CUSTOM'
}

export enum CustomQuestionType {
    AUS_DETAILS = 'AUS_DETAILS',
    LIABILITIES = 'LIABILITIES',
    ASSET_ACCOUNTS = 'ASSET_ACCOUNTS',
}

export interface UnderwritingQuestion<T = any> {
    id: string;
    type: UnderwritingQuestionType;
    customQuesionType?: CustomQuestionType; // for type CUSTOM
    next?: UnderwritingAction; // for type FORM, INFO, TABLE_ENTRY
    text?: string; // the label of the question

    // eslint-disable-next-line max-len
    showNextQuestion?: boolean; // for any question type that has a next property (Not SINGLE_SELECT). Most question require the user the answer the question and hit next before the next question is shown. This property is used to automatically show the next question even if the current question is unanswered, however all questions must still be answered before the step is able to be submitted.

    // TODO variant DROPDOWN was added on the frontend
    variant?: 'SIMPLE' | 'RADIOBUTTON' | 'DROPDOWN'; // if type is SINGLE_SELECT
    choices?: UnderwritingAction[]; // for type SINGLE_SELECT

    fields?: UnderwritingField[]; // if type is FORM

    addButtonText?: string; // if type is FOREACH

    // TODO (idea for improvement) rename this to data and use it for all the question types
    // the 'data' can be used for value injection in the text for all questions
    arrayData?: T[]; // if type is FOREACH
    arrayOptions?: ForeachArrayOptions; // if type is FOREACH
    questions?: UnderwritingQuestion[]; // if type is FOREACH, or GROUP

    entityName?: string; // if type is TABLE_ENTRY
    columns?: { // if type is TABLE_ENTRY
        columnName: string;
        loanFieldId: string;
    }[];
    values?: { // if type is TABLE_ENTRY
        [loanFieldId: string]: string;
    }[]; // this represents the 'answer' of the table

    icon?: UnderwritingIconType; // if type is INFO

    // TODO (idea for improvement) use answer as an array for foreach questions instead of arrayData.i.answers
    answer?: T; // the answer is for sending the data to the api
}

export enum UnderwritingIconType {
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS'
}

export interface UnderwritingAction {
    action: 'CONTINUE' | 'END';
    continueTo?: string;
    text: string;
    confirmationMessage?: string;
    // TODO we should remove sideEffects from this interface and add them to websocket
    sideEffects?: UnderwritingSideEffect[];
}

export interface UnderwritingSideEffect {
    type: 'SUSPEND_LOAN' | 'GENERATE_CONDITION';
    subType?: 'FOREACH'; // generate a sideEffect for each row of a table question
    text?: string;
    description?: string;
}

export enum UnderwritingFieldType {
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
    DATE = 'DATE',
    CURRENCY = 'CURRENCY',
    PERCENTAGE = 'PERCENTAGE'
    // TODO (idea for improvement) - add select & multiselect. We can pass the options in the field object
    // SELECT = 'SELECT',
    // MULTI_SELECT = 'MULTI_SELECT',
}

export interface UnderwritingField {
    dataSourceId: string;
    type: UnderwritingFieldType;
    label: string;
    size: number; // size is the span of the field out of 12 horizontal columns
    isDisabled: boolean;
    helperText?: string;
    value?: string | Date | null; // this represents the 'answer' of the field
    isRequired?: boolean; // it is assumed all fields are required unless specified false
}

export interface ForeachArrayOptions {
    dataSourceId: string;
    iterationName: string;
    indexName: string;
}

export interface UnderwritingStepConfig {
    id: string;
    name: string;
    displayOrder: number;
    questionConfigs: UnderwritingQuestionConfig[];
}

export interface UnderwritingQuestionConfig {
    id: string;
    description: string;
    displayOrder: number;
}
