import api, {
    PermissionType, UnderwritingCategory, UnderwritingQuestionConfig, UnderwritingStepConfig
} from '@api';
import {
    ArrowDownward, ArrowUpward, Edit, RemoveCircleOutline
} from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import {
    IconButton, useConfirm, usePageMessage
} from '@tsp-ui/core';
import { useGetCurrentAccount, useHasPermission } from '@utils';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './UnderwritingCategoryConfigCard.module.scss';


interface UnderwritingQuestionConfigCardProps {
    config: UnderwritingCategory;
    underwritingStepConfig: UnderwritingStepConfig;
    underwritingQuestionConfig: UnderwritingQuestionConfig;
    onUpdate: () => Promise<void>;
}

export function UnderwritingQuestionConfigCard({
    underwritingStepConfig, config, onUpdate, underwritingQuestionConfig
}: UnderwritingQuestionConfigCardProps) {
    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ]);
    const pageMessage = usePageMessage();
    const confirm = useConfirm();
    const { id: clientId } = useGetCurrentAccount();

    const [ moveUpLoading, setMoveUpLoading ] = useState(false);
    const [ moveDownLoading, setMoveDownLoading ] = useState(false);
    const [ isDeleting, setIsDeleting ] = useState(false);

    async function moveConfig(direction: 'up' | 'down') {
        const setLoading = direction === 'up' ? setMoveUpLoading : setMoveDownLoading;
        setLoading(true);

        try {
            await api.underwriting.updateUnderwritingQuestionConfig(clientId, config.id, underwritingStepConfig.id, {
                ...underwritingQuestionConfig,
                displayOrder: underwritingQuestionConfig.displayOrder + (direction === 'up' ? -1 : 1)
            });
            await onUpdate();
        } catch (error) {
            pageMessage.handleApiError('An error occurred while moving the underwriting question configuration', error);
        }

        setLoading(false);
    }

    async function onDelete() {
        if (!await confirm('Are you sure you want to delete this underwriting question configuration? All associated questions will be deleted as well.')) {
            return;
        }

        setIsDeleting(true);

        try {
            await api.underwriting.deleteUnderwritingQuestionConfig(
                clientId, config.id, underwritingStepConfig.id, underwritingQuestionConfig.id
            );
            await onUpdate();
            pageMessage.success('Underwriting question configuration deleted');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while deleting the underwriting question configuration', error);
        }

        setIsDeleting(false);
    }

    return (
        <Paper
            className={styles.root}
            elevation={0}
        >
            <div>
                <Typography>
                    {underwritingQuestionConfig.description}
                </Typography>
            </div>

            <span className={styles.icons}>
                <IconButton
                    loading={moveUpLoading}
                    tooltip={!canManage ? 'You don\'t have permission to move configs' : 'Move up'}
                    disabled={underwritingQuestionConfig.displayOrder === 1
                        || !underwritingQuestionConfig.displayOrder || !canManage}
                    onClick={() => moveConfig('up')}
                >
                    <ArrowUpward color="secondary" />
                </IconButton>

                <IconButton
                    loading={moveDownLoading}
                    tooltip={!canManage
                        ? 'You don\'t have permission to move configs' : 'Move down'}
                    disabled={
                        underwritingQuestionConfig.displayOrder >= underwritingStepConfig.questionConfigs.length
                            || underwritingQuestionConfig.displayOrder === null
                            || !canManage
                    }
                    onClick={() => moveConfig('down')}
                >
                    <ArrowDownward color="secondary" />
                </IconButton>

                <IconButton
                    tooltip={canManage ? 'Edit underwriting question configuration' : 'You do not have permission to edit underwriting question configurations'}
                    disabled={!canManage}
                    component={Link}
                    to={`${underwritingQuestionConfig.id}`}
                >
                    <Edit color="secondary" />
                </IconButton>

                <IconButton
                    tooltip={canManage ? 'Delete underwriting question configuration' : 'You do not have permission to delete underwriting question configurations'}
                    disabled={!canManage}
                    onClick={onDelete}
                    loading={isDeleting}
                    autoMarginLoader={false}
                >
                    <RemoveCircleOutline color="error" />
                </IconButton>
            </span>
        </Paper>
    );
}
