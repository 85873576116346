import api, { PermissionType, UnderwritingCategory, UnderwritingStepConfig } from '@api';
import {
    ArrowDownward, ArrowUpward, Edit, RemoveCircleOutline
} from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import {
    IconButton, SubtleLink, useConfirm, usePageMessage
} from '@tsp-ui/core';
import { useGetCurrentAccount, useHasPermission } from '@utils';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './UnderwritingCategoryConfigCard.module.scss';


interface UnderwritingStepConfigCardProps {
    underwritingStepConfig: UnderwritingStepConfig;
    config: UnderwritingCategory;
    onUpdate: () => Promise<void>;
}

export function UnderwritingStepConfigCard({
    underwritingStepConfig, config, onUpdate
}: UnderwritingStepConfigCardProps) {
    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ]);
    const pageMessage = usePageMessage();
    const confirm = useConfirm();
    const { id: clientId } = useGetCurrentAccount();

    const [ moveUpLoading, setMoveUpLoading ] = useState(false);
    const [ moveDownLoading, setMoveDownLoading ] = useState(false);
    const [ isDeleting, setIsDeleting ] = useState(false);

    async function moveConfig(direction: 'up' | 'down') {
        const setLoading = direction === 'up' ? setMoveUpLoading : setMoveDownLoading;
        setLoading(true);

        try {
            await api.underwriting.updateUnderwritingStepConfig(clientId, config.id, {
                ...underwritingStepConfig,
                displayOrder: underwritingStepConfig.displayOrder + (direction === 'up' ? -1 : 1)
            });
            await onUpdate();
        } catch (error) {
            pageMessage.handleApiError('An error occurred while moving the underwriting step configuration', error);
        }

        setLoading(false);
    }

    async function onDelete() {
        if (!await confirm('Are you sure you want to delete this underwriting step configuration? All associated questions will be deleted as well.')) {
            return;
        }

        setIsDeleting(true);

        try {
            await api.underwriting.deleteUnderwritingStepConfig(clientId, config.id, underwritingStepConfig.id);
            await onUpdate();
            pageMessage.success('Underwriting step configuration deleted');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while deleting the underwriting step configuration', error);
        }

        setIsDeleting(false);
    }

    return (
        <Paper
            className={styles.root}
            elevation={0}
        >
            <div>
                <SubtleLink
                    to={`step/${underwritingStepConfig.id}`}
                >
                    {underwritingStepConfig.name}
                </SubtleLink>

                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {underwritingStepConfig.questionConfigs!.length > 0
                        ? `${underwritingStepConfig.questionConfigs?.length} questions`
                        : 'No questions have been added to this configuration'}
                </Typography>
            </div>

            <span className={styles.icons}>
                <IconButton
                    loading={moveUpLoading}
                    tooltip={!canManage ? 'You don\'t have permission to move configs' : 'Move up'}
                    disabled={underwritingStepConfig.displayOrder === 1
                        || !underwritingStepConfig.displayOrder || !canManage}
                    onClick={() => moveConfig('up')}
                >
                    <ArrowUpward color="secondary" />
                </IconButton>

                <IconButton
                    loading={moveDownLoading}
                    tooltip={!canManage
                        ? 'You don\'t have permission to move configs' : 'Move down'}
                    disabled={
                        underwritingStepConfig.displayOrder >= config.steps.length
                            || underwritingStepConfig.displayOrder === null
                            || !canManage
                    }
                    onClick={() => moveConfig('down')}
                >
                    <ArrowDownward color="secondary" />
                </IconButton>

                <IconButton
                    tooltip={canManage ? 'Edit underwriting category configuration' : 'You do not have permission to edit underwriting category configurations'}
                    disabled={!canManage}
                    component={Link}
                    to={`${underwritingStepConfig.id}`}
                >
                    <Edit color="secondary" />
                </IconButton>

                <IconButton
                    tooltip={canManage ? 'Delete underwriting step configuration' : 'You do not have permission to delete underwriting step configurations'}
                    disabled={!canManage}
                    onClick={onDelete}
                    loading={isDeleting}
                    autoMarginLoader={false}
                >
                    <RemoveCircleOutline color="error" />
                </IconButton>
            </span>
        </Paper>
    );
}
