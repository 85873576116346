import api, {
    ConditionPriorTo, UnderwritingCategory, UnderwritingQuestion, UnderwritingQuestionType, UnderwritingSideEffect,
    UnderwritingStepDetail, underwritingCategoryNameToConditionCategory
} from '@api';
import { CircularProgress, Typography } from '@mui/material';
import {
    Button, Loader, replaceItemById, useAsyncEffect, usePageMessage
} from '@tsp-ui/core';
import { replaceItemByIndex, useGetCurrentAccount } from '@utils';
import {
    Dispatch, SetStateAction, createContext, useCallback, useContext, useState
} from 'react';

import { LoanDetailContext } from '../LoanDetailPage';

import { UnderwritingCategoriesContext } from './UnderwritingCategories';
import styles from './UnderwritingStepSection.module.scss';
import { getUnderwritingStepData, questionTypeToComponentMap } from './underwriting-common';


export interface UnderWritingStepContextValue {
    underwritingStep: UnderwritingStepDetail | undefined;
    setUnderwritingStep: Dispatch<SetStateAction<UnderwritingStepDetail | undefined>>;
    questionIdsToDisplay: string[];
    setQuestionIdsToDisplay: Dispatch<SetStateAction<string[]>>;
    setReadyToSubmit: Dispatch<SetStateAction<boolean>>;
    setSideEffects: Dispatch<SetStateAction<UnderwritingSideEffect[]>>;
    /**
     * Updates the underwriting question with the new value.
     * Handles determining whether the update should be done to a main question or a nested question in a group.
     * Also updates questionIdsToDisplay based on the updated question.
     *
     * updateQuestionsToDisplay defaults to true
     */
    updateUnderwritingQuestion:(updatedQuestion: UnderwritingQuestion, updateQuestionsToDisplay?: boolean) => void;
}

export const UnderWritingStepContext = createContext<UnderWritingStepContextValue>({
    underwritingStep: undefined,
    setUnderwritingStep: () => {},
    questionIdsToDisplay: [],
    setQuestionIdsToDisplay: () => {},
    setReadyToSubmit: () => {},
    updateUnderwritingQuestion: () => {},
    setSideEffects: () => {}
});

interface UnderwritingStepSectionProps {
    underwritingStepId: string;
    setUnderwritingStepId: Dispatch<SetStateAction<string | undefined>>;
    underwritingCategory: UnderwritingCategory;
}

export function UnderwritingStepSection({
    underwritingStepId,
    setUnderwritingStepId,
    underwritingCategory
}: UnderwritingStepSectionProps) {
    const { setUnderwritingCategories } = useContext(UnderwritingCategoriesContext);
    const losLoanId = useContext(LoanDetailContext).loanDetail?.losLoanId;
    const { setConditions } = useContext(LoanDetailContext);
    const pageMessage = usePageMessage();
    const { id: clientId } = useGetCurrentAccount();

    const [ loading, setLoading ] = useState(true);
    const [ submitLoading, setSubmitLoading ] = useState(false);
    const [ underwritingStep, setUnderwritingStep ] = useState<UnderwritingStepDetail>();
    const [ questionIdsToDisplay, setQuestionIdsToDisplay ] = useState<string[]>([]);
    const [ readyToSubmit, setReadyToSubmit ] = useState(false);
    const [ sideEffects, setSideEffects ] = useState<UnderwritingSideEffect[]>([]);

    useAsyncEffect(
        useCallback(async () => {
            setLoading(true);

            try {
                const underwritingStep = await api.underwriting.getUnderwritingStepDetail(
                    clientId,
                    underwritingCategory.id,
                    underwritingStepId
                );
                setUnderwritingStep(underwritingStep);

                const { questionIdsToDisplay, sideEffects } = getUnderwritingStepData(underwritingStep);

                setQuestionIdsToDisplay(questionIdsToDisplay);
                setSideEffects(sideEffects);
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching underwriting step details', error);
            }

            setLoading(false);
        }, [
            clientId, underwritingCategory.id, underwritingStepId, pageMessage
        ])
    );

    /**
     * Determines whether question is a sub-question or a top-level question.
     * If the question is a sub-question, it will update the question within the questions property of the group.
     * If the question is a top-level question, it will update the question directly.
     *
     * Also updates questionIdsToDisplay based on the updated question.
     */
    function updateUnderwritingQuestion(
        updatedQuestion: UnderwritingQuestion, updateQuestionsToDisplay: boolean = true
    ) {
        let updatedStep: UnderwritingStepDetail;

        const isForEachSubQuestion = updatedQuestion.id.split('.').length === 3;
        // if it's a foreach sub-question, update the arrayData on the parent question
        if (isForEachSubQuestion) {
            const [
                parentQuestionId, arrayItemIndexString, subQuestionId
            ] = updatedQuestion.id.split('.');
            const parentQuestion = underwritingStep!.questions.find(
                q => q.id === parentQuestionId
            );
            const arrayData = parentQuestion?.arrayData || [];
            const arrayItemIndex = parseInt(arrayItemIndexString);

            const isAnswerInArrayData = arrayData[arrayItemIndex].answers?.find(
                (a: Record<string, any>) => a.id === subQuestionId
            );

            const updatedArrayData = replaceItemByIndex(arrayData, {
                ...arrayData[arrayItemIndex],
                // if this question's answer is already in the array data, update the value, otherwise add it
                answers: isAnswerInArrayData ? replaceItemById(
                    arrayData[arrayItemIndex].answers,
                    {
                        id: subQuestionId,
                        value: updatedQuestion.answer
                    }
                ) : [
                    ...arrayData[arrayItemIndex].answers || [],
                    {
                        id: subQuestionId,
                        value: updatedQuestion.answer
                    }
                ]
            }, arrayItemIndex);

            updatedStep = {
                ...underwritingStep!,
                questions: replaceItemById(underwritingStep!.questions, {
                    ...parentQuestion!,
                    arrayData: updatedArrayData
                })
            };

            const { questionIdsToDisplay, sideEffects } = getUnderwritingStepData(updatedStep);
            updateQuestionsToDisplay && setQuestionIdsToDisplay(questionIdsToDisplay);
            setSideEffects(sideEffects);

            setUnderwritingStep(updatedStep);
            return;
        }

        // check if it's a question nested in a group
        const [ groupId ] = updatedQuestion.id.split('.');
        const groupQuestion = underwritingStep!.questions.find(
            q => q.id === groupId && q.type === UnderwritingQuestionType.GROUP
        );
        if (groupQuestion) {
            // if groupQuestion is found, we need to update the question within the group
            const updatedGroup = {
                ...groupQuestion!,
                questions: replaceItemById(groupQuestion!.questions!, updatedQuestion)
            };
            updatedStep = {
                ...underwritingStep!,
                questions: replaceItemById(underwritingStep!.questions, updatedGroup)
            };
        } else {
            // if no group is found, update the question directly
            updatedStep = {
                ...underwritingStep!,
                questions: replaceItemById(underwritingStep!.questions, updatedQuestion)
            };
        }

        // TODO if custom question:
        // Right now custom questions do not get updated, most of the components are non-functional demo components
        // When the custom question components are implemented, we may need to make updates here to address any updates
        // to the underwriting step that need to be made

        const { questionIdsToDisplay, sideEffects } = getUnderwritingStepData(updatedStep);
        updateQuestionsToDisplay && setQuestionIdsToDisplay(questionIdsToDisplay);
        setSideEffects(sideEffects);

        setUnderwritingStep(updatedStep);
    }

    async function onSubmit() {
        setSubmitLoading(true);

        try {
            const updatedStep = await api.underwriting.updateUnderwritingStep(
                clientId, underwritingCategory.id, {
                    ...underwritingStep!,
                    isSubmitted: true
                }
            );

            setUnderwritingCategories((categories) => replaceItemById(categories, {
                ...underwritingCategory,
                steps: replaceItemById(
                    underwritingCategory.steps,
                    updatedStep
                )
            }));

            await generateConditions();

            // go to next step
            setUnderwritingStepId((stepId) => {
                const nextStepIndex = underwritingCategory.steps.findIndex(step => step.id === stepId) + 1;
                return underwritingCategory.steps[nextStepIndex]?.id || '';
            });
        } catch (error) {
            pageMessage.handleApiError('An error occurred while submitting underwriting step', error);
        }

        setSubmitLoading(false);
    }

    async function generateConditions() {
        const conditionsToGenerate = sideEffects.filter(sideEffect => sideEffect.type === 'GENERATE_CONDITION');
        if (conditionsToGenerate.length) {
            try {
                const newConditions = await Promise.all(
                    conditionsToGenerate.map((sideEffect) => api.loans.createUnderwritingCondition(
                        clientId,
                            losLoanId!,
                            {
                                // underwritingStepId is not implemented on the backend so it doesn't do anything
                                underwritingStepId,
                                title: sideEffect.text || 'N/A',
                                description: sideEffect.description || 'N/A',
                                source: 'Automated Conditions',
                                category: underwritingCategoryNameToConditionCategory[
                                    underwritingCategory.name as keyof typeof underwritingCategoryNameToConditionCategory // eslint-disable-line max-len
                                ],
                                priorTo: ConditionPriorTo.APPROVAL,
                                requestedFrom: '',
                                daysToReceive: 12,
                                expectedDate: '',
                                allowToClear: false,
                                printExternally: false,
                                printInternally: false
                            }
                    ))
                );

                setConditions((conditions) => [
                    ...conditions,
                    ...newConditions
                ]);

                pageMessage.info(
                    `${newConditions.length === 1 ? 'A condition was' : 'Conditions were'} added to this loan`,
                    newConditions.map(condition => (
                        `${condition.title}: ${condition.description}`
                    ))
                );
            } catch (error) {
                pageMessage.handleApiError('An error occurred while generating conditions', error);
            }
        }
    }

    const numConditionsToGenerate = sideEffects.filter(se => se.type === 'GENERATE_CONDITION').length;

    return loading ? <Loader loading /> : (
        <UnderWritingStepContext.Provider value={{
            underwritingStep,
            setUnderwritingStep,
            questionIdsToDisplay,
            setQuestionIdsToDisplay,
            setReadyToSubmit,
            updateUnderwritingQuestion,
            setSideEffects
        }}
        >
            <div className={styles.underwritingStepSection}>
                <div className={styles.underwritingStepSectionTitle}>
                    <div />

                    <Typography variant="h6">
                        {underwritingStep?.name}
                    </Typography>

                    {/* TODO some of the advanced question types will need an add button */}
                    {/* <div className={styles.addButton}>
                        {addButton}
                    </div> */}
                </div>

                {underwritingStep?.questions?.map(question => {
                    const UnderwritingQuestionCard = questionTypeToComponentMap[question.type];
                    return questionIdsToDisplay.includes(question.id)
                        ? (
                            <UnderwritingQuestionCard
                                question={question}
                                key={question.id}
                            />
                        )
                        : null;
                })}

                <div className={styles.submitButtonContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!readyToSubmit || underwritingStep?.isSubmitted || submitLoading}
                        onClick={onSubmit}
                        tooltip={underwritingStep?.isSubmitted ? 'This step has already been submitted' : ''}
                    >
                        Submit
                    </Button>

                    {submitLoading && (
                        <CircularProgress
                            size={22}
                            className={styles.loading}
                        />
                    )}
                </div>

                {!!numConditionsToGenerate && (
                    <Typography
                        variant="caption"
                        className={styles.sideEffects}
                    >
                        {`Submitting will generate ${numConditionsToGenerate} condition${numConditionsToGenerate > 1 ? 's' : ''}`}
                    </Typography>
                )}
            </div>
        </UnderWritingStepContext.Provider>
    );
}
